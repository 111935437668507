<template>
    <div class="pageView">
        <wdPcForm :schemaName="schemaName" :callback="callback"> </wdPcForm>
    </div>
</template>
<script>
export default {
    name: 'lowcodeFormPage',
    data() {
        return {
            schemaName:'',
        }
    },
    async created() {
        this.schemaName = this.$route.query.schemaName?this.$route.query.schemaName:'Settled'
    },
    methods: {
        callback() {
            // alert('ok')
            this.$message.success('提交成功！')
        },
    }
}
</script>
<style lang="stylus" scoped >
.pageView{
    padding:38px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

</style>